.footer {
    /* position: absolute; */
    bottom: 0;
    width: 100%;
    /* height: 60px;  */
    line-height: 60px; /* Vertically center the text there */
    background-color: #f5f5f5;
  }
  
  
  /* Custom page CSS
  -------------------------------------------------- */
  /* Not required for template or sticky footer method. */
  
  .container {
    width: auto;
    max-width: 680px;
    padding: 0 15px;
    padding-top: 1.2rem;
    text-align: center;
  }
  .color{
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
  }
  .color:hover{
    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
  }
  