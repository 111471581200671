.vte-home{
    /* padding-left : 12rem; */
    /* padding-right : 12rem; */
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}
.text{
    text-align: center;
}
h1{
    padding-left: 1.2rem;
}
p{
    padding-left: 1.2rem;
}