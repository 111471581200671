

/* Center align the text within the three columns below the carousel */
.marketing{
  padding-top: 3rem;
}
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 400;
}
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}

/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -.05rem;
}

.img-bg-color{
  /* height: 600; */
  background-color: #504D4D;
  /* position: absolute; */
  top: 0rem;
  left: 16px;
}
.container_{
  padding-left: 6rem;
  padding-right: 6rem;
  padding-bottom: 1.5rem;
  padding-top: 0rem;
}
